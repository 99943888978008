import { graphql } from "gatsby";
import * as React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout"
import Paragraph from "../components/paragraph/Paragraph";

const NodePagePage = ({data}) => {
  data.isFront = data.nodePage.path.alias === `/startseite`;
  let metaTitle = `${data.nodePage.title} | ${data.site.siteMetadata.title} | ${data.site.siteMetadata.slogan}`;
  let metaDescription = ``;
  if (data.nodePage.metatag) {
    data.nodePage.metatag.forEach((metatag) => {
      if (metatag.attributes.name === `description`) {
        metaDescription = metatag.attributes.content;
      }
    });
  }
  return (
    <Layout data={data}>
      <GatsbySeo
        title={metaTitle}
        description={metaDescription}
      />
      <article className="node--page--full">
        {!data.isFront ? <h1>{data.nodePage.title}</h1> : ``}
        {data.nodePage.relationships.field_main_paragraphs.map((paragraph, i) => {
          return <Paragraph key={i} paragraph={paragraph} data={data} />
        })}
      </article>
    </Layout>
  )
}

export const query = graphql`
query NodePageQuery($alias: String!) {
  site {
    ...SiteHeader
  }
  nodePage(path: {alias: {eq: $alias}}) {
    id
    title
    status
    metatag {
      attributes {
        content
        href
        name
        rel
      }
      tag
    }
    path {
      alias
    }
    relationships {
      field_main_paragraphs {
        ... on paragraph__audio {
          ...ParagraphAudio
        }
        ... on paragraph__call_to_action {
          ...ParagraphCallToAction
        }
        ... on paragraph__columns {
          ...ParagraphColumns
        }
        ... on paragraph__html_text {
          ...ParagraphText
        }
        ... on paragraph__node_reference {
          ...ParagraphNodeReference
        }
        ... on paragraph__slideshow {
          ...ParagraphSlideshow
        }
        ... on paragraph__video_embed {
          ...ParagraphVideoEmbed
        }
      }
    }
  }
  allNodeEvent(
    sort: {fields: field_showtime, order: ASC}
    limit: 5
    filter: {isFuture: {eq: true}}
  ) {
    edges {
      node {
        relationships {
          field_production {
            title
            path {
              alias
            }
            field_link_in_views
          }
          field_location {
            title
            field_address {
              locality
            }
          }
        }
        field_showtime
        field_showtime_hide_time
        isFuture
      }
    }
  }
}
`

export default NodePagePage